@import './prismjs';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {

  // /* The image used */
  // background-image: url(../img/people_network.jpg);

  // /* Full height */
  // // height: 100%; 

  // /* Center and scale the image nicely */
  // background-position: center;
  // // background-repeat: no-repeat;
  // background-size: cover;

  background-color: #FBFBFB;
  // background-color: #f4f6f8;
}

a {
  text-decoration: none;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'), url(../fonts/Gilroy-SemiBold.ttf) format('truetype');
}
:not(pre) > code {
  font-family: Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace;
  background-color: #eeeeee;
  padding: 2px 4px;
  direction: ltr;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  line-height: 1.5;
  font-size: 14px;
}

